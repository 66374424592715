.slider {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner {
  display: flex;
}

.item {
  min-height: 90px;
  min-width: 290px;
  padding: 14px;
  display: flex;
  align-items: center;
}

.item img {
  width: 100%;
  border-radius: 12px;
  pointer-events: none;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.carousel {
  cursor: grab;
  overflow: hidden;
}

@media only screen and (min-width: 576px) {
  .item {
    min-height: 110px;
    min-width: 310px;
  }
}

@media only screen and (min-width: 768px) {
  .item {
    min-height: 140px;
    min-width: 340px;
  }
}

@media only screen and (min-width: 992px) {
  .item {
    min-height: 170px;
    min-width: 370px;
  }
}

@media only screen and (min-width: 1200px) {
  .item {
    min-height: 200px;
    min-width: 400px;
  }
}