.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 250px
}

.banner-image img {
  width: 100%;
}

@media only screen and (min-width: 576px) {
  .logo {
      width: 350px;
  }
}

@media only screen and (min-width: 768px) {
  .logo {
      width: 450px;
  }
}