.services {
  margin-top: 40px;
  margin-bottom: 40px;
}

.item {
  align-items: center;
  justify-content: center;
}

.services h2 {
  margin-bottom: 50px;
}

.service {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin-bottom: 18px;
  padding-bottom: 18px;
}

.service p {
  margin: 0;
}

.service-position p {
  font-size: 2.3rem;
  font-weight: 800;
}

.service-title p {
  font-size: 1.25rem;
  font-weight: 400;
  text-align: center;
}

.service-description p {
  font-size: 1rem;
  text-align: justify;
}

.horizontal-bar {
  border-bottom: 0.2px solid #6c757d40;
}

@media only screen and (max-width: 576px) {
  .service {
    flex-direction: column;
  }
}

@media only screen and (min-width: 576px) {
  .services {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .service {
    gap: 15px;
  }
}

@media only screen and (min-width: 768px) {
  .services {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .service {
    gap: 30px;
  }
}

@media only screen and (min-width: 992px) {
  .services {
    margin-top: 120px;
    margin-bottom: 120px;
  }

  .service {
    gap: 45px;
  }
}

@media only screen and (min-width: 1200px) {
  .services {
    margin-top: 140px;
    margin-bottom: 140px;
  }

  .service {
    gap: 60px;
  }
}