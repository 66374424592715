.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 50px;
}

.footer > p {
  margin: 0;
  font-size: 1.25rem
}