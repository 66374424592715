.project-component {
  margin-top: 40px;
  margin-bottom: 40px;
}

.more-projects-button {
  border: none;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.more-projects-link {
  color: inherit;
  text-decoration: none;
  cursor: grab;
}

.more-projects-icon {
  margin-right: 3px;
}

@media only screen and (min-width: 576px) {
  .project-component {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 768px) {
  .project-component {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}

@media only screen and (min-width: 992px) {
  .project-component {
    margin-top: 120px;
    margin-bottom: 120px;
  }
}

@media only screen and (min-width: 1200px) {
  .project-component {
    margin-top: 140px;
    margin-bottom: 140px;
  }
}