.contact-button {
  overflow: auto;
  vertical-align: bottom;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-button > a {
  width: 200px;
  height: 40px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #c48637;
  font-size: 1.1em;
  border: 1px solid #de9b45;
  transition: 0.3s;
}

.contact-button p {
  margin: 0;
}

.contact-button > a:hover {
  background-color: #de9b45;
  color: white;
}

.header > a {
  width: 110px !important;
  height: 40px !important;
  font-size: 0.65em;
}

@media only screen and (min-width: 576px) {
  .contact-button > a {
    width: 250px;
  }
}

@media only screen and (min-width: 768px) {
  .contact-button > a {
    width: 300px;
  }
}

@media only screen and (min-width: 992px) {
  .contact-button > a {
    width: 350px;
  }
}

@media only screen and (min-width: 1200px) {
  .contact-button > a {
    width: 400px;
  }
}