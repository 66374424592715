.bio-component {
  margin-top: 40px;
}

.bio-component h2 {
  margin-bottom: 40px;
}

.bio-component p {
  font-size: 1rem;
  max-width: 76%;
  text-align: justify;
}

.bio-component img {
  width: 100%;
  padding: 0px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.text {
  justify-content: center;
}

.content {
  justify-content: center;
}

@media only screen and (min-width: 576px) {
  .bio-component {
    margin-top: 50px;
  }

  .bio-component h2 {
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 768px) {
  .bio-component {
    margin-top: 80px;
  }
}

@media only screen and (min-width: 992px) {
  .bio-component {
    margin-top: 120px;
  }
}

@media only screen and (min-width: 1200px) {
  .bio-component {
    margin-top: 140px;
  }
}